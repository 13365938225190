<template>
  <div id="OrthophotoContent"
       class="OrthophotoContent">
    <img class="goBack"
         :src="goBack"
         alt=""
         @click="$router.back()" />
  </div>
</template>

<script>
import Api from '@/utils/api.js';
import { MapDetail } from '@/utils/javaApi.js';

import goBack from '@/assets/img/Icon/goBack.png';

export default {
  data() {
    return {
      goBack,
      data: null,
      googleLayer: '',
      satelliteLayer: '',
      layer: '',
      kmlId: '',
      center: '',
      path: '',
    };
  },
  mounted() {
    this.kmlId = this.$route.query.id;
    this.$post(MapDetail(), {
      kml_id: this.kmlId,
      shooting_date: '',
    }).then((res) => {
      if (res.code == 1) {
        if (res.data.lat2) {
          this.center = [
            (
              (parseFloat(res.data.lng) + parseFloat(res.data.lng2)) /
              2
            ).toFixed(8),
            (
              (parseFloat(res.data.lat) + parseFloat(res.data.lat2)) /
              2
            ).toFixed(8),
          ];
          console.log(
            (
              (parseFloat(res.data.lng) + parseFloat(res.data.lng2)) /
              2
            ).toFixed(8)
          );
        } else {
          this.center = [res.data.lng, res.data.lat];
        }
        this.path = res.data.path;
        this.initMap();
      }
    });
  },
  methods: {
    initMap() {
      if (this.$online) {
        this.layer = new AMap.TileLayer();
        // 卫星图
        this.satelliteLayer = new AMap.TileLayer.Satellite();
      } else {
        this.layer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png';
          },
          zIndex: 10,
        });
        this.satelliteLayer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeSatelliteMap() + z + '/' + x + '/' + y + '.png';
          },
          zIndex: 10,
        });
      }
      // 创建地图
      let centerPoint = this.$Center;
      if (this.center[0] != null) {
        centerPoint = this.center;
      }

      this.map = new AMap.Map('OrthophotoContent', {
        resizeEnable: true,
        expandZoomRange: true,
        zoom: 18, //地图显示的缩放级别
        zooms: [3, 20],
        center: centerPoint,
        layers: [this.satelliteLayer],
      });
      //   xyzTileLayer.setMap(this.map);
      this.toLoad();
    },
    toLoad() {
      let _this = this;
      this.googleLayer = new AMap.TileLayer({
        zIndex: 12,
        getTileUrl: function (x, y, z) {
          return _this.path + '/map' + '/' + z + '/' + x + '/' + y + '.png';
        },
      });
      this.googleLayer.setMap(this.map);
    },
  },
};
</script>

<style scoped lang="scss">
#OrthophotoContent {
  width: 100%;
  height: 100%;
  background-color: #a7ecff;
  position: relative;
  .goBack {
    z-index: 100000;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 10px;
    left: 20px;
    cursor: pointer;
  }
}
</style>
