export default {
    //登录
    Login() {
        return `/login/`;
    },
    //发送验证码
    sendCloudSms() {
        return `/tencent/sendCloudSms/`;
    },

    // 统计页配置接口 超管
    getRrackAllCountConfig() {
        return `/admin/Track/trackIndex`;
    },




    // 超管公司列表
    getLiveSuperList() {
        return `/admin/Prison/superList`;
    },
    // 3d图模型列表
    get3DModelList() {
        return `/admin/air_route/getModelList`;
    },

    // 绑定socket
    bindWebSocketUrl() {
        return `/base/common/bindClient`;
    },
    // 发送自己的定位
    sendLocation() {
        return `/admin/Chat/sendLocation`;
    },






    // socket地址
    getWebSocketUrl() {
        // return `ws://159.75.244.206:9044/websocket/`;
        return `wss://www.hnmusk.cn/websocket/`; // 海南
        // return `ws://192.168.0.152:8091/websocket/`
    },

    //Cesium官网申请的Token
    CesiumToken() {
        return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkYzMyZTA3MS0xNWM0LTQ5OTYtYjUxOC1iMDMyNWQ3MTNiYzQiLCJpZCI6MjAxNDYsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJhc3ciLCJnYyIsInByIl0sImlhdCI6MTU5MTMyMjA4OH0.033EQ89IbRlxArdyzA9crs2802hoYXtmgZNjnIiedPs";
    },
    //Cesium天地图地址
    TianDiTuMap() {
        return `http://192.168.8.137:8081/map/TianDiTu/satellite` //测试服
        // return `http://43.4.201.100:10010/map/TianDiTu/satellite_TMS`; //特警
    },
    //高德卫星地址
    GaoDeSatelliteMap() {
        return `http://192.168.8.137:8081/map/GaoDe/satellite/`; //测试服
        // return `http://127.0.0.1:8081/GaoDe/satellite/`;
        // return `http://43.4.201.100:10010/map/GaoDe/Satellite/`; //特警
    },
    //高德街道地址
    GaoDeStreetMap() {
        return `http://192.168.8.137:8081/map/GaoDe/Street/`; //测试服
        // return `http://127.0.0.1:8081/GaoDe/Street/`;
        // return `http://43.4.201.100:10010/map/GaoDe/Street/`; //特警
    },
    //Terra接口区=================================================================================
    //获取token
    obtain_token() {
        return `/terra-hz/terra-rescon-be/v2/store/obtain_token`;
    },



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////JAVA//////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // 管理员下所有直播的无人机
    getAdminLiveList() {
        // return `/admin/Prison/adminList`;
        return `/team/adminList`;
    },
    // 获取某个视频的播放地址
    getLiveUrl() {
        return `/live/getPlayUrl`;
    },
    // 管理员团队列表
    getLiveAdminList() {
        // return `/admin/Prison/adminList`;
        return `/team/adminList`;
    },

    // 资源 =======================================
    getSecondPmFigure() {
        // 获取建图任务列表
        return `/project/task/getSecondPmFigure`
    },
    // 加载资源列表
    loadAllResource() {
        return `/resource/loadAll`
        // return `/resource/orThoPhotoList`
    },
    // 删除模型
    deleteModel() {
        return `/resource/model/delete`
    },
    // 删除正射
    deleteOrthophoto() {
        return `/resource/web/map/delete`
    },
    // 编辑模型
    updateModel() {
        return `/resource/model/update`
    },
    // 编辑正射
    updateOrthophoto() {
        return `/resource/map/update`
    },
    // 大文件上传
    webuploadUrl() {
        // return "/Java/large/path/upload"; // 开发环境
        return "/jflight/large/path/upload";
    },
    // 大文件解压
    uploadCompress() {
        return "/large/path/compress";
    },

    // 大文件缓存清除
    uploadClear() {
        return "/large/clear";
    },
    // 添加模型
    AddModel() {
        return "/resource/model/add";
    },

    // 添加正射
    AddYinshe() {
        return "/resource/map/add";
    },
    //添加模型
    get3DUpDataUrl() {
        // return "/Java/resource/model/add"; // 开发环境
        return "/jflight/resource/model/add";
    },
    //添加正射
    getOrthophotoUpDataUrl() {
        // return "/Java/resource/orThoPhotoAdd"; // 开发环境
        return "/jflight/resource/orThoPhotoAdd";
    },
    // 快拼对比标注详情
    mapMarkDetail() {
        return `/resource/mapMarkDetail`
    },
    // 保存快拼对比标注
    mapMarkAdd() {
        return `/resource/mapMarkAdd`
    },

    // 自由上传图片视频
    addUserMedia() {
        // return "/Java/resource/addUserMediaFolder"; // 开发环境
        return "/jflight/resource/addUserMediaFolder";
    },
    // 获取自由媒体文件夹

    // 创建自由媒体文件夹
    createUserFolder() {
        return `/resource/creationFolder`
    },
    // 修改自由媒体文件夹
    updateFolderName() {
        return `/resource/updateFolderName`
    },
    // 删除自由媒体文件夹
    delUserFolder() {
        return `/resource/delFolder`
    },
    // 获取自由媒体资源
    getUserMedia() {
        return `/resource/getFile`

    },
    // 自由媒体资源删除
    userMediaDel() {
        return `/resource/userMediaDel`
    },

    // 快拼列表
    getPuzzleList() {
        return `/resource/map/list`
    },


    // 团队人员 ========================================
    // 头像图片上传
    headIconUpload() {
        return `/upfile/upHeadIcon`
    },
    // 获取全部下级成员
    getAllTeamUser() {
        return `/team/getAllTeamUser`
    },
    // 切换飞手启禁用
    switchFlyerState() {
        return `/flyer/update`
    },
    // 编辑用户
    userEdit() {
        return `/admin/userEdit`
    },
    // 删除用户
    userDel() {
        return `/admin/userDel`
    },
    // 新增团队(含管理员)
    addTeam() {
        return `/admin/register`
    },
    // 新增飞手
    addUser() {
        return `/admin/userAdd`
    },
    // 删除团队
    removeTeam() {
        return `/team/removeTeam`
    },
    // 获取团队
    teamList() {
        return `/team/teamList`
    },
    // 获取团队内的成员
    getTeamUser() {
        return `/team/getTeamUser`
    },
    // 获取团队信息(云空间容量,人员数量等)
    getTeamInformation() {
        return `/team/getTeamInformation`
    },

    // 航线 ====================================================
    // 航线媒体资源列表
    airRouteMedia() {
        // return `/mobile/route/airRouteImage`
        return `/mobile/route/getWayPointNewMedia`
    },
    // 重命名任务目录
    editTaskDir() {
        return `/mobile/route/editTaskDir`
    },
    // 删除任务目录
    deleteTaskDir() {
        return `/mobile/route/deleteTaskDir`
    },
    // 获取团队任务列表(改版)
    loadTaskList() {
        return `/project/task/loadTaskList`
    },
    // 创建任务目录
    createTaskDir() {
        return `/mobile/route/createTaskDir`
    },

    // 新建航线任务
    submitCreateLine() {
        return `/mobile/route/AirRouteAdd`;
    },
    // 编辑建线
    submitEditLine() {
        return `/mobile/route/airRouteEdit`;
    },
    // 航线删除
    airRouteAllDel() {
        return `/mobile/route/airRouteAllDel`
    },
    // 获取航线任务的正射
    // 新建建图任务
    submitCreateArea() {
        return `/project/task/figure/add`;
    },
    // 编辑建图
    submitEditArea() {
        return `/project/task/buildFigureEdit`;
    },
    // 建图任务删除
    buildFigureDel() {
        return `/project/task/buildFigureDel`
    },
    // // 获取建图任务的模型 ====== 5/30暂不开放功能
    // bfOrthoModel () {
    //     return `/mobile/route/bfOrthoModel`
    // },

    // 聊天语音 =========================================
    // 上传音频文件,返回路径
    uploadingVoiceFile() {
        return `/groupChat/webUploadingVoiceFile`
    },

    // 轨迹记录
    // 轨迹详情
    getTrackDetail() {
        return `/track/history/detail`;
    },
    // 记录页接口
    getTrackLists() {
        // return `/admin/Track/trackLists`;
        return `/track/history/list`;
    },
    // 记录页查询导出
    exportTrackLists() {
        return `track/history/export`
    },
    // 记录页配置接口
    getTrackListsConfig() {
        // return `/admin/Track/trackListIndex`;
        return `/track/history/trackListIndex`;
    },
    // 统计页 普通管理员
    getRrackAllCount() {
        // return `/admin/Track/trackAllCount`;
        return `/track/count/list`;
    },
    // 统计页 超管
    getRrackAllSuperCount() {
        // return `/admin/Track/trackAllSuper`;
        return `/track/count/list`;
    },
    // 轨迹删除
    delRecord() {
        return `/track/history/deleteTrack `
    },
    // 编辑飞行轨迹备注
    updateRemark() {
        // return `/admin/Track/trackAllSuper`;
        return `/track/history/updateRemark`;
    },

    // 拉取正射地图详情(2号地图专用)
    mapDetail() {
        return `/mobile/route/mapDetail`;
    },

    // 任务 ==========================================================================================
    //自由任务表格接口
    getTaskList() {
        return "/project/task/taskList"
    },
    //自由任务-任务列表和人员
    taskListAndPerson() {
        return "/project/task/taskListIndex"
    },
    //自由任务-添加任务类型
    taskTypeAdd() {
        return "/project/task/taskTypeAdd"
    },
    // 自由任务-删除任务类型
    taskTypeDelete() {
        return "/project/task/taskTypeDelete"
    },
    //自由任务-修改任务类型
    taskTypeEdit() {
        return "/project/task/taskTypeEdit"
    },
    //   // 自由任务-获取虚拟护栏
    //   getNotFlyArea(){
    //     return "/base/common/getNotFlyArea"
    //   },

    // 自由任务-新增任务保存接口
    taskAdd() {
        return "/project/task/taskAdd"
    },
    //自由任务-查看
    taskDetail() {
        return "/project/task/taskDetail"
    },
    // 自由任务-列表-删除
    taskDelete() {
        return "/project/task/taskDelete"
    },

    // 审批 =====================================================================================
    ReviewList() {
        return `/approval/ReviewList`
    },
    ReviewAdd() {
        return `/approval/ReviewAdd`
    },
    ReviewEdit() {
        return `/approval/ReviewEdit`
    },
    ReviewDelete() {
        return `/approval/ReviewDelete`
    },

    // 全景图 ===========================================
    panoramaList() {
        return `/panorama/panoramaList`
    },
    panoramaAdd() {
        return `/panorama/panoramaAdd`
    },
    panoramaDel() {
        return `/panorama/panoramaDel`
    },
    panoramaUpdate() {
        return `/panorama/panoramaUpdate`
    },


    getMapMark() {
        return `/resource/getMapMark`
    },
    //获取轨迹标签列表
    getMarkToTrack() {
        return `/track/history/getMarkToTrack`
    },
    //添加标签
    addMark() {
        return `/track/history/addMark`
    },
    //删除标签
    deleteMark() {
        return `/track/history/deleteMark`
    },
    //修改标签
    updateMarkName() {
        return `/track/history/updateMarkName`
    },
    //指定飞行轨迹添加标签
    addMarkToTrack() {
        return `/track/history/addMarkToTrack`
    },
};