// 用户上传头像接口(简易版）-- 可用于上传文件
export const upfile = function () {
    return "/upfile/upHeadIcon"
}
// 用户修改自身信息
export const editUserPass = function () {
    return "/user/editUserPass"
}

// 用户获取自身信息
export const getUserInfo = function () {
    return "/admin/getUserInfo"
}

// 团队筛选列表
export const ChildTeamList = function () {
    return "/team/childTeamList"
};

// 团队监控列表
export const getAdminList = function () {
    return "/team/adminList"
};

// 设备-列表
export const DeviceList = function () {
    return "/device/loadPaging"
}

// 获取所有虚拟护栏数据
export const GetNotFlyArea = function () {
    return "/track/lawrecorder/getNotFlyArea"
}

// 无人机型号表
export const DeviceType = function () {
    return "/device/type"
}

// 电池列表
export const DeviceBatterList = function () {
    return "/device/batter/loadPaging"
}

// 设备保养页面数据
export const DeviceMaintainData = function () {
    return "/device/maintain/load"
}

// 添加无人机设备
export const DeviceAdd = function () {
    return "/device/add"
}

// 编辑无人机设备
export const DeviceUpdate = function () {
    return "/device/update"
}

// 解绑无人机设备
export const DeviceDel = function () {
    return "/device/delete"
}

// 获取群聊记录
export const GetGroupMsgRecord = function () {
    return "/groupChat/getGroupMsgRecord"
}
// 获取私聊记录
export const getPrivateChatRecord = function () {
    return "/groupChat/getPrivateChatRecord"
}

// 获取团队虚拟护栏列表
export const GetTeamNotFlyList = function () {
    return "/track/lawrecorder/getTeamNotFlyList"
}

// 获取团队虚拟护栏列表---重命名
export const ModifyNotFlyName = function () {
    return "/track/lawrecorder/modifyNotFlyName"
}

// 获取团队虚拟护栏列表---删除
export const DelNotFly = function () {
    return "/track/lawrecorder/delNotFly"
}

// 获取团队虚拟护栏列表---添加虚拟护栏
export const AddNotFlyArea = function () {
    return "/track/lawrecorder/addNotFlyArea"
}

// 获取执法记录仪详情(改版)
export const lawDetail = function () {
    return "/track/lawrecorder/lawDetail"
}

// 电池解绑
export const BatterDelete = function () {
    return "/device/batter/delete"
}

// 通用文件上传
export const UploadFile = function () {
    // return "/Java/upfile/upload"  // 开发环境
    return "/jflight/upfile/upload"
}

// 添加模型
export const AddModel = function () {
    return "/resource/model/add";
}

// 添加正射
export const AddYinshe = function () {
    return "/resource/map/add";
}

// 获取正射详情
export const MapDetail = function () {
    return `/resource/web/orThoPhotoDetail`;
}
// 全景图 新增
export const PanoramawebAdd = function () {
    return "/panoramaweb/add";
    // return "/jflight/panoramaweb/add";
}

// 全景图 列表
export const PanoramawebList = function () {
    return "/panoramaweb/List";
}

// 全景图 删除
export const PanoramawebDel = function () {
    return "/panoramaweb/delet";
}

// 全景图 修改
export const PanoramawebUpdate = function () {
    return "panoramaweb/update";
}

// 全景图 组数据
export const PanoramawebDetail = function () {
    return "panoramaweb/imageList";
}